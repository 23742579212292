import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { business } from '../data';

@Injectable({
  providedIn: 'root',
})
export class WhatsappBuilderService {
  constructor() {}

  getLink(message: string) {
    const params = new HttpParams().set('phone', `51${business.phone[0]}`).set('text', message);
    return `https://api.whatsapp.com/send?${params}`;
  }
}
