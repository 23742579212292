import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './theme/navbar/navbar.component';
import { FooterComponent } from './theme/footer/footer.component';
import { PreloaderComponent } from './theme/preloader/preloader.component';
import { RouterModule } from '@angular/router';
import { NgIconsModule } from '@ng-icons/core';
import { bootstrapPersonFill, bootstrapChevronUp, bootstrapBoxArrowInRight } from '@ng-icons/bootstrap-icons';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [NavbarComponent, FooterComponent, PreloaderComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    NgIconsModule.withIcons({ bootstrapChevronUp, bootstrapBoxArrowInRight, bootstrapPersonFill }),
  ],
  exports: [PreloaderComponent, NavbarComponent, FooterComponent],
})
export class CoreModule {}
