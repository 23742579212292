import { ClientComment, Plan, Service } from './models';

class Business {
  constructor(public phone: string[], public address: string, public email: string, public linkToMaps: string) {}

  getFormattedPhone() {
    return this.phone[0].match(/.{1,3}/g).join(' ');
  }
}

export let business = new Business(
  ['948939227', '(01) 4801702'],
  'Jr. 28 de julio Mz. D Lt. 13 – Pillco Marca - Huánuco',
  'admin@infistel.pe',
  'https://www.google.com/maps/place/SERCOR+S.A.C./@-9.959355,-76.249021,1119m/data=!3m1!1e3!4m5!3m4!1s0x0:0xbbe544400a886f9a!8m2!3d-9.959358!4d-76.2490202?hl=es-419'
);

let features = ['100% fibra óptica', 'Internet ilimitado y simétrico', 'mayor velocidad'];
export let plans: Plan[] = [
  {
    title: '40 Mbps',
    features,
    price: '34.00',
  },
  {
    title: '60 Mbps',
    features,
    price: '44.00',
  },
  {
    title: '100 Mbps',
    features,
    price: '54.00',
  },
  {
    title: '150 Mbps',
    features,
    price: '64.00',
  },
  {
    title: '200 Mbps',
    features,
    price: '74.00',
  },
  {
    title: '400 Mbps',
    features,
    price: '94.00',
  },
  {
    title: '600 Mbps',
    features,
    price: '114.00',
  },
];

export let services: Service[] = [
  {
    title: 'Internet de fibra óptica',
    description: '¡Disfruta de internet a la velocidad de la luz! descarga rápidamente lo que necesites',
    icon: 'bootstrapHddNetwork',
  },
  {
    title: 'Internet inalámbrico',
    description: 'Olvídate de los cables con internet inalámbrico para todo tu hogar',
    icon: 'bootstrapWifi',
  },
  {
    title: 'consultoría',
    description: 'Gracias a nuestra gran experiencia podemos encontrar las mejores soluciones para ti',
    icon: 'bootstrapPersonBoundingBox',
  },
];

const clientImgPath = 'assets/img/clients/';
export const clientsComments: ClientComment[] = [
  {
    comment: 'Lorem ipsum dolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `assets/img/clients/1.png`,
  },
  {
    comment: 'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `${clientImgPath}2.png`,
  },
  {
    comment: 'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `${clientImgPath}3.png`,
  },
  {
    comment: 'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `${clientImgPath}4.png`,
  },
  {
    comment: 'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `${clientImgPath}5.png`,
  },
  {
    comment: 'Lorem ipsudolorsitamet,consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.',
    personTitle: 'CEO at Linder',
    personName: 'Jhon Doe',
    image: `${clientImgPath}6.png`,
  },
];
