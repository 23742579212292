import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(private router: Router, private titleService: Title) {}

  ngOnInit() {
    if (typeof window === 'undefined') return;
    this.recallJsFunctions();
    this.setTitle();
  }

  setTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle: string | undefined;
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string | undefined) => {
        if (title) {
          this.titleService.setTitle(`${title} | Infistel`);
        } else {
          this.titleService.setTitle('Infistel | Proveedora de servicios de internet | 100% Fibra óptica | Inalámbrico');
        }
      });
  }

  recallJsFunctions() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        $('.preloader').fadeIn('slow');
      }
    });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/main.js');
        $('.preloader').fadeOut('slow');
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        globalThis.scrollTo(0, 0);
      });
  }
}
