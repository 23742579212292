import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'nosotros',
    data: { title: 'Nosotros' },
    loadChildren: () => import('./modules/about-us/about-us.module').then(m => m.AboutUsModule),
  },

  {
    path: 'planes',
    data: { title: 'Planes' },
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
  },
  {
    path: 'servicios',
    data: { title: 'Servicios' },
    loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'cobertura',
    data: { title: 'Cobertura' },
    loadChildren: () => import('./modules/coverage/coverage.module').then(m => m.CoverageModule),
  },
  {
    path: 'contacto',
    data: { title: 'Contacto' },
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'error',
    data: { title: 'Error' },
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
  },

  {
    path: '**',
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule),
  }, // This line will remain down from the whole component list
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
