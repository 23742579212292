import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingCardComponent } from './components/pricing-card/pricing-card.component';
import { PriceListComponent } from './components/price-list/price-list.component';
import { SwiperModule } from 'swiper/angular';
import { NgIconsModule } from '@ng-icons/core';
import {
  bootstrapWhatsapp,
  bootstrapGift,
  bootstrapChatLeftQuote,
  bootstrapQuote,
  bootstrapCheck2,
} from '@ng-icons/bootstrap-icons';
import { WhatsappBuilderService } from './services/whatsapp-builder.service';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ClientFeedbackComponent } from './components/client-feedback/client-feedback.component';
import { TitleIconComponent } from './components/title-icon/title-icon.component';

@NgModule({
  declarations: [
    PricingCardComponent,
    PriceListComponent,
    TabComponent,
    TabsComponent,
    ClientFeedbackComponent,
    TitleIconComponent,
  ],
  imports: [
    CommonModule,
    SwiperModule,
    NgIconsModule.withIcons({
      bootstrapQuote,
      bootstrapGift,
      bootstrapWhatsapp,
      bootstrapChatLeftQuote,
      bootstrapCheck2,
    }),
  ],
  exports: [
    PricingCardComponent,
    PriceListComponent,
    TabsComponent,
    TabComponent,
    ClientFeedbackComponent,
    TitleIconComponent,
  ],
  providers: [WhatsappBuilderService],
})
export class SharedModule {}
